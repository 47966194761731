<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="mx-auto my-auto text-center" v-if="!loadedAnagrafica">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    @invalid-submit="onInvalidSubmit"
    v-else
  >
    <div class="accordion" id="accordionAnagraficaSocieta">
      <form autocomplete="new-off">
        <div class="card shadow shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaSocieta-headingOne"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaSocieta-collapseOne"
                aria-expanded="true"
                aria-controls="accordionAnagraficaSocieta-collapseOne"
              >
                Dati Societari
              </button>
            </h6>
            <div
              id="accordionAnagraficaSocieta-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaSocieta-headingOne"
            >
              <Field
                name="affiliazione_secondaria"
                type="hidden"
                class="form-control"
                :value="societa.affiliazione_secondaria"
              />
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="mb-4 col-md-6">
                        <label class="fw-bold text-gray-600"
                          >Denominazione*
                        </label>
                        <TextInput
                          name="denominazione"
                          type="text"
                          :value="societaFromProps.denominazione"
                          :disabled="societa.affiliazione_secondaria"
                        />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Comitato affiliazione*</label
                        >

                        <!-- INSERIRE CAMPO COMITATO REGIONALE -->
                        <SelectInput
                          :options="comitati"
                          :value="societaFromProps.id_comitato"
                          name="id_periferico"
                          :disabled="societa.affiliazione_secondaria"
                        />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Codice Affiliazione*</label
                        >
                        <TextInput
                          name="cod_affiliazione"
                          type="text"
                          :value="societaFromProps.cod_affiliazione"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-12">
                        <label class="fw-bold text-gray-600"
                          >Descrizione breve</label
                        >
                        <TextInput
                          name="sigla"
                          type="text"
                          :value="societaFromProps.descrizione_breve"
                        />
                      </div>
                    </div>
                    <div class="row" v-show="!societa.affiliazione_secondaria">
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Data costituzione*</label
                        >
                        <Datepicker
                          v-model="societaFromProps.data_costituzione"
                          name="data_costituzione"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                        />
                        <Field
                          name="data_costituzione"
                          type="hidden"
                          class="form-control"
                          v-model="societaFromProps.data_costituzione"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_costituzione"
                        />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Data prima affiliazione*</label
                        >

                        <Datepicker
                          name="data_prima_aff"
                          v-model="societaFromProps.data_prima_aff"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                        />
                        <Field
                          name="data_prima_aff"
                          type="hidden"
                          class="form-control"
                          v-model="societaFromProps.data_prima_aff"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_prima_aff"
                        />
                      </div>
                    </div>
                    <div class="row" v-show="!societa.affiliazione_secondaria">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Rappresentante legale*</label
                        >
                        <div class="dp__input_wrap text-gray-600">
                          <Field
                            name="nome_rl"
                            type="text"
                            class="form-control"
                            v-model="societaFromProps.nome_rl"
                            @input="getPersone($event.target.value)"
                            autocomplete="new-off"
                          />
                          <svg
                            @click="
                              resetLegali();
                              societaFromProps.id_resplegale = null;
                              societaFromProps.nome_rl = null;
                            "
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            class="dp__icon dp__clear_icon dp__input_icons"
                          >
                            <path
                              d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                            ></path>
                            <path
                              d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                            ></path>
                          </svg>
                          <Field
                            name="id_resplegale"
                            type="hidden"
                            class="form-control"
                            v-model="societaFromProps.id_resplegale"
                          />
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="id_resplegale"
                          />
                        </div>
                        <ListPersone
                          :list="legali"
                          @hideList="resetLegali"
                          @selectedLegale="
                            resetLegali();
                            societaFromProps.id_resplegale = $event.value;
                            societaFromProps.nome_rl = $event.name;
                          "
                          :focusOnInput="true"
                        ></ListPersone>
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Natura giuridica*</label
                        >
                        <SelectInput
                          :options="societaTipo"
                          name="id_tipo"
                          :value="societaFromProps.id_tipo"
                        />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Polisportiva</label
                        >

                        <SelectInput
                          :options="polisportiva"
                          name="polisportiva"
                          :value="societaFromProps.polisportiva"
                        />
                      </div>
                    </div>
                    <div class="row" v-show="!societa.affiliazione_secondaria">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Codice fiscale*</label
                        >
                        <TextInput
                          name="cod_fisc"
                          type="text"
                          :value="societaFromProps.cod_fisc"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Partita IVA</label>
                        <TextInput
                          name="cod_iva"
                          type="text"
                          :value="societaFromProps.cod_iva"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="mb-4 col-md-4"
                        v-show="!societa.affiliazione_secondaria"
                      >
                        <label class="fw-bold text-gray-600">PEC*</label>
                        <TextInput
                          name="email_pec"
                          type="email"
                          :value="societaFromProps.email_pec"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">E-mail*</label>
                        <TextInput
                          name="email"
                          type="email"
                          :value="societaFromProps.email"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Sito web</label>
                        <TextInput
                          name="web"
                          type="text"
                          :value="societaFromProps.web"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Skype</label>
                        <TextInput
                          name="skype"
                          type="text"
                          :value="societaFromProps.skype"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Facebook</label>
                        <TextInput
                          name="facebook"
                          type="text"
                          :value="societaFromProps.facebook"
                        />
                      </div>
                    </div>
                    <div class="row" v-show="!societa.affiliazione_secondaria">
                      <div class="mb-2 col-md-2">
                        <label class="fw-bold text-gray-600"
                          >Società cessata</label
                        >
                        <SelectInput
                          :options="polisportiva"
                          name="cessata"
                          :value="societaFromProps.cessata"
                        />
                        <!-- <select
                        class="form-select"
                        aria-label=""
                        @change="setCessata"
                      >
                        <option selected>-</option>
                        <option value="1">Si</option>
                        <option value="2">No</option>
                      </select> -->
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
      </form>
      <div
        class="card shadow shadow mb-7"
        v-show="!societa.affiliazione_secondaria"
      >
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaSocieta-headingTwo"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaSocieta-collapseTwo"
              aria-expanded="false"
              aria-controls="accordionAnagraficaSocieta-collapseTwo"
            >
              Sede Legale
            </button>
          </h6>
          <div
            id="accordionAnagraficaSocieta-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="accordionAnagraficaSocieta-headingTwo"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Nazione*</label>
                      <SelectInput
                        :options="nazioni"
                        :value="societaFromProps.nazione_legale"
                        name="nazione_legale"
                        @changeSelect="
                          changeselectedItaliaL($event);
                          societaFromProps.id_provincia = 0;
                          societaFromProps.id_comune = 0;
                        "
                      />
                      <Field
                        name="nazione_legale"
                        type="hidden"
                        class="form-control"
                        v-model="societaFromProps.nazione_legale"
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Provincia* </label>
                      <SelectInput
                        :options="province"
                        :value="societaFromProps.id_provincia"
                        name="id_provincia_legale"
                        :disabled="selectedItaliaL ? false : true"
                        @changeSelect="changeselectedProvinciaL($event)"
                      />
                      <Field
                        name="id_provincia"
                        type="hidden"
                        class="form-control"
                        v-model="societaFromProps.id_provincia"
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Comune*</label>
                      <Field
                        name="comune"
                        type="text"
                        class="form-control"
                        v-model="societaFromProps.comune"
                        @input="
                          spliceComuniIstat();
                          setComuni(
                            societaFromProps.comune,
                            societaFromProps.id_provincia
                          );
                        "
                        @focus="inputFocus = 'comuneL'"
                        @keyup.delete="
                          spliceComuniIstat();
                          societaFromProps.id_comune = 0;
                        "
                        :disabled="
                          selectedProvinciaL && selectedItaliaL ? false : true
                        "
                        autocomplete="new-off"
                      />
                      <Field
                        name="id_comune"
                        type="hidden"
                        class="form-control"
                        v-model="societaFromProps.id_comune"
                      />
                      <ListComuni
                        v-if="comuniIstat && inputFocus === 'comuneL'"
                        :list="comuniIstat"
                        @hideList="spliceComuniIstat"
                        @selectedElement="
                          societaFromProps.comune = $event.name;
                          societaFromProps.id_comune = $event.value;
                          spliceComuniIstat();
                        "
                      ></ListComuni>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_comune"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="mb-4 col-md-5">
                      <label class="fw-bold text-gray-600">Indirizzo*</label>
                      <TextInput
                        name="indirizzo"
                        type="text"
                        :value="societaFromProps.indirizzo"
                      />
                    </div>
                    <div class="mb-4 col-md-3">
                      <label class="fw-bold text-gray-600">C.A.P.*</label>
                      <TextInput
                        name="cap"
                        type="text"
                        :value="societaFromProps.cap"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-4 col-md-5">
                      <label class="fw-bold text-gray-600">Presso</label>
                      <TextInput
                        name="presso"
                        type="text"
                        :value="societaFromProps.presso"
                      />
                    </div>
                    <div class="mb-4 col-md-5">
                      <label class="fw-bold text-gray-600">Località</label>
                      <TextInput
                        name="localita"
                        type="text"
                        :value="societaFromProps.localita"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-2 col-md-3">
                      <label class="fw-bold text-gray-600">Cellulare</label>

                      <TextInput
                        name="mobile"
                        type="text"
                        :value="societaFromProps.mobile"
                      />
                    </div>
                    <div class="mb-2 col-md-3">
                      <label class="fw-bold text-gray-600">Telefono 1*</label>
                      <TextInput
                        name="telefono"
                        type="text"
                        :value="societaFromProps.telefono"
                      />
                    </div>
                    <div class="mb-2 col-md-3">
                      <label class="fw-bold text-gray-600">Telefono 2</label>

                      <TextInput
                        name="telefono2"
                        type="text"
                        :value="societaFromProps.telefono2"
                      />
                    </div>
                    <div class="mb-2 col-md-3">
                      <label class="fw-bold text-gray-600">Fax</label>

                      <TextInput
                        name="fax"
                        type="text"
                        :value="societaFromProps.fax"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow mb-7" v-show="!societa.affiliazione_secondaria">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaSocieta-headingThree"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaSocieta-collapseThree"
              aria-expanded="false"
              aria-controls="accordionAnagraficaSocieta-collapseThree"
            >
              Recapito postale
            </button>
          </h6>
          <div
            id="accordionAnagraficaSocieta-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="accordionAnagraficaSocieta-headingThree"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Nazione*</label>
                      <SelectInput
                        :options="nazioni"
                        :value="societaFromProps.nazione_postale"
                        name="nazione_postale"
                        @changeSelect="
                          changeselectedItaliaP($event);
                          societaFromProps.id_provincia_postale = 0;
                          societaFromProps.id_comune_postale = 0;
                        "
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Provincia*</label>
                      <SelectInput
                        :options="province"
                        :value="societaFromProps.id_provincia_postale"
                        name="id_provincia_postale"
                        :disabled="selectedItaliaP ? false : true"
                        @changeSelect="changeselectedProvinciaP($event)"
                      />
                    </div>
                    <Field
                      name="id_provincia_postale"
                      type="hidden"
                      class="form-control"
                      v-model="societaFromProps.id_provincia_postale"
                    />
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Comune*</label>
                      <Field
                        name="comune_postale"
                        type="text"
                        class="form-control"
                        v-model="societaFromProps.comune_postale"
                        @input="
                          spliceComuniIstat();
                          setComuni(
                            societaFromProps.comune_postale,
                            societaFromProps.id_provincia_postale
                          );
                        "
                        @focus="inputFocus = 'comuneP'"
                        @keyup.delete="
                          spliceComuniIstat();
                          societaFromProps.id_comune_postale = 0;
                        "
                        :disabled="
                          selectedProvinciaP && selectedItaliaP ? false : true
                        "
                      />

                      <Field
                        name="id_comune_postale"
                        type="hidden"
                        class="form-control"
                        v-model="societaFromProps.id_comune_postale"
                        autocomplete="new-off"
                      />
                      <ListComuni
                        v-if="comuniIstat && inputFocus === 'comuneP'"
                        :list="comuniIstat"
                        @hideList="spliceComuniIstat"
                        @selectedElement="
                          societaFromProps.comune_postale = $event.name;
                          societaFromProps.id_comune_postale = $event.value;
                          spliceComuniIstat();
                        "
                      ></ListComuni>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_comune_postale"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-4 col-md-5">
                      <label class="fw-bold text-gray-600">Indirizzo*</label>
                      <TextInput
                        name="indirizzo_postale"
                        type="text"
                        :value="societaFromProps.indirizzo_postale"
                      />
                    </div>
                    <div class="mb-4 col-md-3">
                      <label class="fw-bold text-gray-600">C.A.P.*</label>
                      <TextInput
                        name="cap_postale"
                        type="text"
                        :value="societaFromProps.cap_postale"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-2 col-md-5">
                      <label class="fw-bold text-gray-600 pt-1">Presso</label>
                      <TextInput
                        name="nome_postale"
                        type="text"
                        :value="societaFromProps.presso_postale"
                      />
                    </div>
                    <div class="mb-2 col-md-5">
                      <label class="fw-bold text-gray-600">Località</label>
                      <TextInput
                        name="localita_postale"
                        type="text"
                        :value="societaFromProps.localita_postale"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow mb-7" v-show="!societa.affiliazione_secondaria">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaSocieta-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaSocieta-collapseFour"
              aria-expanded="false"
              aria-controls="accordionAnagraficaSocieta-collapseFour"
            >
              Recapito fiscale
            </button>
          </h6>
          <div
            id="accordionAnagraficaSocieta-collapseFour"
            class="accordion-collapse collapse"
            aria-labelledby="accordionAnagraficaSocieta-headingFour"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Nazione</label>
                      <SelectInput
                        :options="nazioni"
                        :value="societaFromProps.nazione_fiscale"
                        name="nazione_fiscale"
                        @changeSelect="
                          changeselectedItaliaF($event);
                          societaFromProps.id_provincia_fiscale = 0;
                          societaFromProps.id_comune_fiscale = 0;
                        "
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Provincia</label>
                      <SelectInput
                        :options="province"
                        :value="societaFromProps.id_provincia_fiscale"
                        name="id_provincia_fiscale"
                        :disabled="selectedItaliaF ? false : true"
                        @changeSelect="changeselectedProvinciaF($event)"
                      />
                    </div>
                    <div class="mb-4 col-md-4">
                      <label class="fw-bold text-gray-600">Comune</label>
                      <Field
                        name="comune_fiscale"
                        type="text"
                        class="form-control"
                        v-model="societaFromProps.comune_fiscale"
                        @input="
                          spliceComuniIstat();
                          setComuni(
                            societaFromProps.comune_fiscale,
                            societaFromProps.id_provincia_fiscale
                          );
                        "
                        @focus="inputFocus = 'comuneF'"
                        @keyup.delete="
                          spliceComuniIstat();
                          societaFromProps.id_comune_fiscale = 0;
                        "
                        :disabled="
                          selectedProvinciaF && selectedItaliaF ? false : true
                        "
                      />
                      <Field
                        name="id_comune_fiscale"
                        type="hidden"
                        class="form-control"
                        v-model="societaFromProps.id_comune_fiscale"
                        autocomplete="new-off"
                      />
                      <ListComuni
                        v-if="comuniIstat && inputFocus === 'comuneF'"
                        :list="comuniIstat"
                        @hideList="spliceComuniIstat"
                        @selectedElement="
                          societaFromProps.comune_fiscale = $event.name;
                          societaFromProps.id_comune_fiscale = $event.value;
                          spliceComuniIstat();
                        "
                      ></ListComuni>
                      <ErrorMessage
                        class="help-message"
                        style="color: red"
                        name="id_comune_fiscale"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="mb-4 col-md-5">
                      <label class="fw-bold text-gray-600">Indirizzo</label>
                      <TextInput
                        name="indirizzo_fiscale"
                        type="text"
                        :value="societaFromProps.indirizzo_fiscale"
                      />
                    </div>
                    <div class="mb-4 col-md-3">
                      <label class="fw-bold text-gray-600">C.A.P.</label>
                      <TextInput
                        name="cap_fiscale"
                        type="text"
                        :value="societaFromProps.cap_fiscale"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-2 col-md-5">
                      <label class="fw-bold text-gray-600">Presso</label>
                      <TextInput
                        name="presso_fiscale"
                        type="text"
                        :value="societaFromProps.presso_fiscale"
                      />
                    </div>
                    <div class="mb-2 col-md-5">
                      <label class="fw-bold text-gray-600">Località</label>
                      <TextInput
                        name="localita_fiscale"
                        type="text"
                        :value="societaFromProps.localita_fiscale"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow mb-7" v-show="!societa.affiliazione_secondaria">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaSocieta-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaSocieta-collapseFive"
              aria-expanded="false"
              aria-controls="accordionAnagraficaSocieta-collapseFive"
            >
              Coordinate bancarie
            </button>
          </h6>
          <div
            id="accordionAnagraficaSocieta-collapseFive"
            class="accordion-collapse collapse"
            aria-labelledby="accordionAnagraficaSocieta-headingFive"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="mb-2 col-md-4">
                      <label class="fw-bold text-gray-600">Banca</label>
                      <TextInput
                        name="banca"
                        type="text"
                        :value="societaFromProps.banca"
                      />
                    </div>
                    <div class="mb-2 col-md-4">
                      <label class="fw-bold text-gray-600 pt-1">Agenzia</label>
                      <TextInput
                        name="indirizzo_banca"
                        type="text"
                        :value="societaFromProps.agenzia"
                        @InputEvent="societaFromProps.agenzia = $event"
                      />
                    </div>
                    <div class="mb-2 col-md-4">
                      <label class="fw-bold text-gray-600 pt-1">IBAN*</label>
                      <TextInput
                        name="iban"
                        type="text"
                        :value="societaFromProps.iban"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow mb-7">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaSocieta-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaSocieta-collapseSeven"
              aria-expanded="false"
              aria-controls="accordionAnagraficaSocieta-collapseSeven"
            >
              Tutela contro la violenza di genere
            </button>
          </h6>
          <div
            id="accordionAnagraficaSocieta-collapseSeven"
            class="accordion-collapse collapse"
            aria-labelledby="accordionAnagraficaSocieta-headingSix"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="mb-2 col-md-4">
                      <label class="fw-bold text-gray-600"
                        >Cognome responsabile</label
                      >
                      <TextInput
                        name="tutore_minori_cognome"
                        type="text"
                        :value="societaFromProps.tutore_minori_cognome"
                      />
                    </div>
                    <div class="mb-2 col-md-4">
                      <label class="fw-bold text-gray-600"
                        >Nome responsabile</label
                      >
                      <TextInput
                        name="tutore_minori_nome"
                        type="text"
                        :value="societaFromProps.tutore_minori_nome"
                      />
                    </div>
                    <div class="mb-2 col-md-4">
                      <label class="fw-bold text-gray-600"
                        >Codice fiscale responsabile</label
                      >
                      <TextInput
                        name="tutore_minori_cod_fiscale"
                        type="text"
                        :value="societaFromProps.tutore_minori_cod_fiscale"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-2 col-md-6">
                      <label class="fw-bold text-gray-600"
                        >Telefono responsabile</label
                      >
                      <TextInput
                        name="tutore_minori_telefono"
                        type="text"
                        :value="societaFromProps.tutore_minori_telefono"
                      />
                    </div>
                    <div class="mb-2 col-md-6">
                      <label class="fw-bold text-gray-600"
                        >Email responsabile</label
                      >
                      <TextInput
                        name="tutore_minori_mail"
                        type="text"
                        :value="societaFromProps.tutore_minori_mail"
                      />
                    </div>
                  </div>
                  <div class="mb-7 bg-light-danger px-8 py-6 rounded">
                    <div class="text-danger fs-7">
                      Attenzione! In caso di modifica del campo "<strong
                        >Email responsabile</strong
                      >", i documenti <br />"<strong
                        >Modello di organizzazione e controllo ex D.
                        Lgs.39/2021</strong
                      >" e "<strong
                        >Codice di Condotta ex D. Lgs. 39/2021</strong
                      >" verranno invalidati. <br />
                      Bisognerà procedere con la rigenerazione dei documenti
                      attraverso il bottone "<strong>Scarica moduli</strong>"
                      alla sezione
                      <router-link
                        :to="`/societa/dettaglio-societa/documenti-societa/${societa.id}`"
                        >Documenti</router-link
                      >"
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow mb-7">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaSocieta-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaSocieta-collapseEight"
              aria-expanded="false"
              aria-controls="accordionAnagraficaSocieta-collapseEight"
            >
              Campi pickleball
            </button>
          </h6>
          <div
            id="accordionAnagraficaSocieta-collapseEight"
            class="accordion-collapse collapse"
            aria-labelledby="accordionAnagraficaSocieta-headingSix"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="mb-2 col-md-4">
                      <label class="fw-bold text-gray-600">Numero campi</label>
                      <TextInput
                        name="campi_pickleball"
                        type="number"
                        :value="societaFromProps.campi_pickleball"
                      />
                    </div>
                    <div class="mb-2 col-md-4">
                      <label class="fw-bold text-gray-600"
                        >Proprietario campi pickleball</label
                      >
                      <TextInput
                        name="campi_pickleball_proprieta"
                        type="checkbox"
                        :checked="societaFromProps.campi_pickleball_proprieta"
                        :value="societaFromProps.campi_pickleball_proprieta"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>
      <div class="card shadow mb-7">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaSocieta-headingFour"
          >
            <button
              class="accordion-button collapsed fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaSocieta-collapseSix"
              aria-expanded="false"
              aria-controls="accordionAnagraficaSocieta-collapseSix"
            >
              Credenziali di accesso
            </button>
          </h6>
          <div
            id="accordionAnagraficaSocieta-collapseSix"
            class="accordion-collapse collapse"
            aria-labelledby="accordionAnagraficaSocieta-headingSix"
          >
            <div class="accordion-body p-0 m-0">
              <!--begin::Card body-->
              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="col-md-4 mb-2">
                      <label class="fw-bold text-gray-600">User</label>
                      <input
                        v-if="societaFromProps.username"
                        name="username"
                        type="text"
                        class="form-control"
                        v-model="societaFromProps.username"
                        disabled
                      />
                      <input
                        v-if="!societaFromProps.username"
                        name="username"
                        type="text"
                        class="form-control"
                        v-model="newUsername"
                      />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label class="fw-bold text-gray-600"
                        >E-mail utente
                        <Popper
                          v-if="societaFromProps.can_subscribe"
                          arrow
                          content="L'email di accesso al sistema non potrà essere cambiata."
                          style="margin: -12px"
                        >
                          <i
                            class="bi bi-info-circle-fill text-center"
                          ></i> </Popper
                      ></label>
                      <input
                        name="email_utente"
                        type="text"
                        class="form-control"
                        v-model="societaFromProps.email_utente"
                        :disabled="!societaFromProps.can_subscribe"
                      />
                    </div>
                    <div class="text-end col-md-4">
                      <div class="mt-5">
                        <button
                          v-if="societaFromProps.can_subscribe"
                          type="button"
                          class="badge bg-light-success text-success rounded fs-6"
                          @click="addUtente()"
                        >
                          Crea utenza
                        </button>
                        <button
                          v-if="societaFromProps.can_reset_password"
                          type="button"
                          class="badge bg-light-warning text-warning rounded fs-6"
                          @click="resetPsw(societaFromProps.user_guid)"
                        >
                          Reset password
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end::Card body-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalAddPersona :fromSocieta="true" />

    <!--end::details View-->
    <div class="text-end">
      <button v-if="!readOnly" class="badge bg-secondary rounded blueFit fs-5">
        Salva Modifiche
      </button>
    </div>
  </Form>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  getCurrentInstance,
} from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

// componenti standard
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";

// componenti
import TextInput from "../../../components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "../../../components/components-fit/utility/inputs/SelectInput.vue";
import ListPersone from "../../../components/components-fit/utility/ListPersone.vue";
import ListComuni from "../../../components/components-fit/utility/ListComuni.vue";

// gestione stato
import { useStore } from "vuex";

// richieste dati
import { updateSocieta } from "../../../requests/updateSocieta";

// validazione e alert
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

// check
import { checkCodFisc } from "../../../requests/checkCodiceFiscale";

// css
import "vue3-date-time-picker/dist/main.css";

import ModalAddPersona from "@/components/components-fit/persone/ModalAddPersona.vue";

import Popper from "vue3-popper";

import { addUtenza, resetPassword } from "@/requests/utenzeRequests";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import { viewAnagrafica } from "@/requests/societaAnagrafica";

// componente
export default defineComponent({
  name: "anagrafica-societa",
  components: {
    Popper,
    Form,
    Field,
    ErrorMessage,
    Datepicker,
    TextInput,
    SelectInput,
    ListPersone,
    ListComuni,
    Loading,
    ModalAddPersona,
  },
  emits: ["societaView"],
  props: ["societa", "campiLocked"],
  setup(props, { emit }) {
    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const loadedAnagrafica = ref(false);
    // primi eventi
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Anagrafica Societa", [
        "Lista Societa",
        "Dettaglio Societa",
      ]);
      await viewAnagrafica(props.societa.id).then((res) => {
        societaFromProps.value = res.results[0];
        nomeLegale.value = societaFromProps.value.nome_rl;
        // EVENTI INIZIALI
        changeselectedItaliaL(societaFromProps.value.nazione_legale);
        changeselectedProvinciaL(societaFromProps.value.id_provincia);
        changeselectedItaliaP(societaFromProps.value.nazione_postale);
        changeselectedProvinciaP(societaFromProps.value.id_provincia_postale);
        changeselectedItaliaF(societaFromProps.value.nazione_fiscale);
        changeselectedProvinciaF(societaFromProps.value.id_provincia_fiscale);
        loadedAnagrafica.value = true;
      });
    });

    // INIZIALIZZAZIONI
    const societaFromProps = ref(null);

    const inputFocus = ref("");

    // legali
    const selectedItaliaL = ref(false);
    const selectedProvinciaL = ref(false);

    // rappresentanti legali
    const nomeLegale = ref(null);

    // postale
    const selectedItaliaP = ref(false);
    const selectedProvinciaP = ref(false);

    //fiscale
    const selectedItaliaF = ref(false);
    const selectedProvinciaF = ref(false);

    //societa cessata
    const cessata = ref(false);

    // lookups - store GETTERS
    const nazioni = computed(() =>
      store.getters.getStateFromName("societa_ubicazione")
    );
    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const societaTipo = computed(() =>
      store.getters.getStateFromName("societa_tipo")
    );

    // lookups chiamate - store ACTIONS
    const keys = ref("d|su|ip|sti");
    if (
      !province.value ||
      (province.value && !province.value.length) ||
      !nazioni.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    // altre store ACTIONS
    const setComuni = (comuneString, id) => {
      if (comuneString.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString,
            codp: id,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const getComitati = () => {
      store.dispatch("setStoreListData", {
        apiLink: globalApi.LOOKUP_COMITATI,
        itemName: "comitati_list",
      });
    };
    getComitati();

    const resetLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    // FUNZIONI esterne - store MUTATIONS
    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    // FUNZIONI nella pagina
    const getPersone = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };

    const changeselectedItaliaL = (e) => {
      if (e === "IT") {
        selectedItaliaL.value = true;
        selectedProvinciaL.value = false;
      } else {
        selectedItaliaL.value = false;
        societaFromProps.value.id_provincia = 0;
        societaFromProps.value.comune = "";
      }
    };
    const changeselectedProvinciaL = (e) => {
      if (e === undefined || e === null || e === "-") {
        societaFromProps.value.id_provincia = null;
        selectedProvinciaL.value = false;
        societaFromProps.value.comune = "";
      } else if (e != societaFromProps.value.id_provincia) {
        selectedProvinciaL.value = true;
        societaFromProps.value.id_provincia = parseInt(e);
        societaFromProps.value.comune = "";
      } else {
        selectedProvinciaL.value = true;
      }
    };

    const changeselectedItaliaP = (e) => {
      if (e === "IT") {
        selectedItaliaP.value = true;
        selectedProvinciaP.value = false;
      } else {
        selectedItaliaP.value = false;
        societaFromProps.value.id_provincia_postale = 0;
        societaFromProps.value.comune_postale = "";
      }
    };

    const changeselectedProvinciaP = (e) => {
      if (e === undefined || e === null || e === "-") {
        societaFromProps.value.id_provincia_postale = null;
        selectedProvinciaP.value = false;
        societaFromProps.value.comune_postale = "";
      } else if (e != societaFromProps.value.id_provincia_postale) {
        selectedProvinciaP.value = true;
        societaFromProps.value.id_provincia_postale = parseInt(e);
        societaFromProps.value.comune_postale = "";
      } else {
        selectedProvinciaP.value = true;
      }
    };

    const changeselectedItaliaF = (e) => {
      if (e === "IT") {
        selectedItaliaF.value = true;
        selectedProvinciaF.value = false;
      } else {
        selectedItaliaF.value = false;
        societaFromProps.value.id_provincia_fiscale = 0;
        societaFromProps.value.comune_fiscale = "";
      }
    };

    const changeselectedProvinciaF = (e) => {
      if (e === undefined || e === null || e === "-") {
        societaFromProps.value.id_provincia_fiscale = null;
        selectedProvinciaF.value = false;
        societaFromProps.value.comune_fiscale = "";
      } else if (e != societaFromProps.value.id_provincia_fiscale) {
        selectedProvinciaF.value = true;
        societaFromProps.value.id_provincia_fiscale = parseInt(e);
        societaFromProps.value.comune_fiscale = "";
      } else {
        selectedProvinciaF.value = true;
      }
    };

    const setCessata = (e) => {
      if (e.target.value === "1") {
        cessata.value = true;
      } else {
        cessata.value = false;
      }
    };

    // form validation
    const schema = yup.object().shape({
      denominazione: yup.string().required("Obbligatorio"),
      cod_affiliazione: yup.string().required("Obbligatorio"),
      /*   .min(8, "Il codice di affiliazione deve avere 8 caratteri")
        .max(8, "Il codice di affiliazione deve avere 8 caratteri"), */
      // descrizione_breve: yup.mixed().defined(),
      sigla: yup.string(),
      id_periferico: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      cod_iva: yup.string(),
      affiliazione_secondaria: yup.bool(),
      cod_fisc: yup
        .string()
        .when(["cod_iva", "affiliazione_secondaria"], {
          is: (cod_iva, affiliazione_secondaria) => {
            return cod_iva === "" && !affiliazione_secondaria;
          },
          then: yup.string().required("Obbligatorio"),
          otherwise: yup.string(),
        })
        .test(
          "checkCodFisc",
          "Inserire un codice fiscale valido",
          async function (value) {
            if (
              value.length === 11 &&
              value != societaFromProps.value.cod_fisc
            ) {
              const isValidCodFisc = await checkCodFisc(
                value,
                globalApi.COD_FISC_SOCIETA
              );
              return isValidCodFisc;
            } else if (
              value === societaFromProps.value.cod_fisc ||
              props.societa.affiliazione_secondaria
            )
              return true;
            else {
              return false;
            }
          }
        ),
      id_resplegale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .moreThan(0, "Seleziona un rappresentante dalla lista dinamica...")
        .required("Seleziona un rappresentante dalla lista dinamica...")
        .typeError("Seleziona un rappresentante dalla lista dinamica..."),
      id_tipo: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      polisportiva: yup.string().nullable(),
      email_pec: yup
        .string()
        .email("Inserire una pec valida")
        .required("Obbligatorio"),
      email: yup
        .string()
        .email("Inserire una email valida")
        .required("Email obbligatoria"),
      web: yup.string(),
      skype: yup.string(),
      facebook: yup.string(),
      // RESO NON OBBLIGATORIO PER NOMINA RESPONSABILE TUTTELA
      // DA RIPRISTINARE IL 10 DI LUGLIO (NEL THEN)
      // yup.string().required("Obbligatorio").nullable()
      data_costituzione: yup.string().when(["affiliazione_secondaria"], {
        is: (affiliazione_secondaria) => {
          return !affiliazione_secondaria;
        },
        then: yup.string().nullable(),
        otherwise: yup.string().nullable(),
      }),
      data_prima_aff: yup.string().when(["affiliazione_secondaria"], {
        is: (affiliazione_secondaria) => {
          return !affiliazione_secondaria;
        },
        then: yup.string().required("Obbligatorio").nullable(),
        otherwise: yup.string().nullable(),
      }),
      //legale
      nazione_legale: yup.string().nullable(),
      id_provincia: yup
        .number()
        .nullable()
        .transform((value) => (isNaN(value) ? undefined : value))
        .when("nazione_legale", {
          is: (nazione_legale) => {
            return nazione_legale === "IT";
          },
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .required("Obbligatorio")
            .moreThan(0, "Obbligatorio"),
        }),
      id_comune: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("id_provincia", {
          is: (id_provincia) => {
            return id_provincia > 0;
          },
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .moreThan(0, "Seleziona un comune dalla lista dinamica...")
            .required("Seleziona un comune dalla lista dinamica..."),
        }),
      indirizzo: yup.string().required("Obbligatorio"),
      cap: yup.string().when("nazione_legale", {
        is: (nazione_legale) => {
          return nazione_legale === "IT";
        },
        then: yup
          .string()
          .required("Obbligatorio")
          .min(5, "Inserire un CAP valido")
          .max(5, "Inserire un CAP valido"),
        otherwise: yup.string().nullable(),
      }),
      presso: yup.string().nullable(),
      localita: yup.string(),
      mobile: yup.string(),
      telefono: yup.string().required("Obbligatorio"),
      telefono2: yup.string(),
      fax: yup.string(),

      // postale
      nazione_postale: yup.string().nullable(),
      id_provincia_postale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("nazione_postale", {
          is: (nazione_postale) => {
            return nazione_postale === "IT";
          },
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .required("Obbligatorio")
            .moreThan(0, "Obbligatorio"),
        }),
      id_comune_postale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("id_provincia_postale", {
          is: (id_provincia_postale) => {
            return id_provincia_postale > 0;
          },
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .moreThan(0, "Seleziona un comune dalla lista dinamica...")
            .required("Seleziona un comune dalla lista dinamica..."),
        }),
      indirizzo_postale: yup.string().required("Obbligatorio"),
      cap_postale: yup.string().when("nazione_postale", {
        is: (nazione_postale) => {
          return nazione_postale === "IT";
        },
        then: yup
          .string()
          .required("Obbligatorio")
          .min(5, "Inserire un CAP valido")
          .max(5, "Inserire un CAP valido"),
        otherwise: yup.string().nullable(),
      }),
      nome_postale: yup.string().nullable(),
      localita_postale: yup.string(),

      //fiscale
      nazione_fiscale: yup.string().nullable(),
      id_provincia_fiscale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("nazione_fiscale", {
          is: (nazione_fiscale) => {
            return nazione_fiscale === "IT";
          },
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
        }),
      id_comune_fiscale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("nazione_fiscale", {
          is: (nazione_fiscale) => {
            return nazione_fiscale === "IT";
          },
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable(),
        }),
      indirizzo_fiscale: yup.string(),
      cap_fiscale: yup.string(),
      presso_fiscale: yup.string(),
      localita_fiscale: yup.string(),
      banca: yup.string(),
      agenzia: yup.string().nullable(),
      iban: yup.string().required("Obbligatorio"),
      // .min(27, "inserire IBAN valido")
      // .max(27, "inserire IBAN valido"),
      username: yup.string(),
      email_utente: yup.string(),
      pasw_script: yup.string(),
      cessata: yup.string(),
      tutore_minori_nome: yup.string().nullable(),
      tutore_minori_cognome: yup.string().nullable(),
      tutore_minori_cod_fiscale: yup.string().nullable(),
      tutore_minori_telefono: yup.string().nullable(),
      tutore_minori_mail: yup.string().nullable(),
      campi_pickleball: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value)),
      campi_pickleball_proprieta: yup
        .boolean()
        .nullable()
        .transform((value) => (value == "" ? false : value)),
    });

    const isLoading = ref(false);
    // invio form
    const onSubmit = async (values) => {
      if (values.cod_fisc === "") {
        values = {
          ...values,
          cod_fisc: values.cod_iva,
        };
      }
      values = {
        id: societaFromProps.value.id,
        ...values,
      };
      isLoading.value = true;
      updateSocieta(values).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          Swal.fire({
            html: "Società modificata con successo",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          emit("societaView");
        } else {
          Swal.fire({
            html: res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore, riprovare più tardi.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
      });
    };

    // form non valido
    const onInvalidSubmit = () => {
      let strMsg = "Compila i campi obbligatori";
      Swal.fire({
        html: strMsg,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    };

    const regexEmail = ref(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);

    const newUsername = ref(null);

    const addUtente = () => {
      if (!societaFromProps.value.email_utente) {
        alertFailed("Inserire una indirizzo email utente");
        return false;
      } else if (regexEmail.value.test(societaFromProps.value.email_utente)) {
        Swal.fire({
          title: "Sei Sicuro?",
          html: "Procedendo verrà creata l'utenza.",
          icon: "warning",
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: "Annulla",
          confirmButtonText: "Crea utenza",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn fw-bold btn-light-success",
            cancelButton: "btn fw-bold btn-light-warning",
          },
        }).then(async (e) => {
          if (e.isDismissed) {
            return;
          }
          if (e.isConfirmed) {
            isLoading.value = true;
            await addUtenza(
              societaFromProps.value.username
                ? societaFromProps.value.username
                : newUsername.value,
              societaFromProps.value.email_utente,
              null,
              null,
              props.societa.id,
              null,
              null,
              "SOC"
            ).then((res) => {
              if (res.status == 200) {
                alertSuccess("Utenza creata correttamente");
                emit("societaView");
              } else {
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
              isLoading.value = false;
            });
          }
        });
      } else {
        alertFailed("Inserire un email utente valida");
      }
      return false;
    };

    const resetPsw = (adb2c_guid) => {
      Swal.fire({
        title: "Sei Sicuro?",
        html: "Procedendo verrà resettata la password di accesso al management FITP.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Reset password",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await resetPassword(adb2c_guid).then((res) => {
            if (res.status == 200) {
              alertSuccess("Password resettata correttamente");
              emit("societaView");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    // page bindings
    return {
      // campi e variabili pagina
      schema,
      inputFocus,
      societaFromProps,
      cessata,
      polisportiva: [
        { id: 1, label: "SI" },
        { id: 0, label: "NO" },
      ],
      selectedItaliaL,
      selectedProvinciaL,
      selectedItaliaP,
      selectedProvinciaP,
      selectedItaliaF,
      selectedProvinciaF,
      // liste
      comitati: computed(() =>
        store.getters.getStateFromName("resultscomitati_list")
      ),
      nomeLegale,
      nazioni,
      province,
      societaTipo,
      // liste old style
      legali: computed(() => store.getters.personeSocieta),
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      // funzionalità
      format,
      changeselectedItaliaL,
      changeselectedProvinciaL,
      changeselectedItaliaP,
      changeselectedProvinciaP,
      changeselectedItaliaF,
      changeselectedProvinciaF,
      setCessata,
      setComuni,
      spliceComuniIstat,
      getPersone,
      resetLegali,
      spliceLegali,
      onSubmit,
      onInvalidSubmit,
      isLoading,

      addUtente,
      resetPsw,

      readOnly: computed(() => store.getters.readOnlySelectedRoute),

      newUsername,
      loadedAnagrafica,
    };
  },
});
</script>

<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
